<template>
<div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="categories" sort-by="calories" class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Pix</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark rounded class="mb-2" v-bind="attrs" v-on="on">
                            <v-icon small>
                                {{ icons.mdiPlus }}
                            </v-icon>
                            Novo Pix
                        </v-btn>
                    </template>
                    <v-card>
                       <BarTitleDialog :title="formTitle" @close="dialog = false"></BarTitleDialog>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="editedItem.pix_key" outlined label="Chave Pix" placeholder="Email, Telefone, CPF, CNPJ"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" x-large text @click="close">
                                Fechar
                            </v-btn>
                            <v-btn :loading="loading" x-large color="primary" @click="save">
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>

        <template v-slot:item.status="{ item }">
            {{status(item.status)}}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                    {{ icons.mdiPencilOutline }}
                </v-icon>
            </template>
            <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                    {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
            <span>Excluir</span>
            </v-tooltip>
            <!-- <v-icon color="primary" class="mr-2" @click="editItem(item)">
                {{ icons.mdiPencilOutline }}
            </v-icon>
            <v-icon color="error" @click="deleteItem(item)">
                {{ icons.mdiDeleteOutline }}
            </v-icon>  -->
        </template>
        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>
    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapActions
} from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
    components: {
        MsgDelete,
        NoDataTable,
        BarTitleDialog
    },
    data: () => ({
        categoryItems: [],
        loadingTable: false,
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        dialogDelete: false,
        dialog: false,
        loading: false,
        headers: [{
                text: 'ID',
                align: 'start',
                value: 'id',
            },
            {
                text: 'Chave Pix',
                align: 'start',
                value: 'pix_key'
            },
            {
                text: '',
                align: 'end',
                value: 'actions',
                sortable: false,
            },
        ],
        categories: [],
        editedIndex: -1,
        editedItem: {
            description: '',
        },
        defaultItem: {
            description: '',
        },
        options: {},
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Novo Pix' : 'Editar Pix'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options: {
            handler() {
                this.initialize()
            },
        },
    },

    created() {
        this.getCategory().then((response) => {
            this.categoryItems = response.data
        })

        this.initialize()
    },

    methods: {
        ...mapActions('pix', ['getAll', 'store', 'update', 'delete' ]),
        initialize() {
            this.loadingTable = true

            const {
                page,
                itemsPerPage
            } = this.options

            const payload = {
                itemsPerPage: itemsPerPage || 10,
                page,
                search: this.search,
            }

            this.getAll(payload)
                .then(response => {
                    this.categories = response.data.data
                    this.totalStagesPaginate = response.data.total
                    this.numberOfPagesPaginate = response.data.last_page
                    this.currentPagePaginate = response.data.current_page
                })
                .finally(() => {
                    this.loadingTable = false
                })
        },

        editItem(item) {
            this.editedIndex = this.categories.indexOf(item)
            this.editedItem = {
                ...item
            }
            this.dialog = true
        },

        deleteItem(item) {
            this.dialogDelete = true
            this.editedItem = item
        },

        deleteItemConfirm() {
            const index = this.categories.indexOf(this.editedItem)
            this.delete(this.editedItem)
                .then(() => {
                    this.categories.splice(index, 1)
                    this.closeDelete()
                    this.$toast.success('Excluído com sucesso !')
                })
                .catch()
                .finally()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },

        save() {
            this.loading = true
            if (this.editedIndex > -1) {
                const index = this.editedIndex
                this.update(this.editedItem)
                    .then(response => {
                        const category = response.data
                        Object.assign(this.categories[index], category)
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.store(this.editedItem)
                    .then(response => {
                        const category = response.data
                        this.categories.push(category)
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },
}
</script>
